<template>
  <div>
    <a-descriptions
      :column="1"
      class="iotplt-descriptions iotplt-descriptions-max-10"
    >
      <a-descriptions-item label="事件性质">
        {{ eventName }}
      </a-descriptions-item>

      <a-descriptions-item label="订单月份">
        {{ data.month }}
      </a-descriptions-item>

      <a-descriptions-item label="月订单状态">
        {{ data.status }}
      </a-descriptions-item>

      <a-descriptions-item>
        <span slot="label">
          <a-tooltip>
            <template slot="title">
              未出账时停机保号、流量使用详情订单金额不计入月订单结算金额
            </template>
            月订单结算金额(元)
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        {{ data.total_fee | formatCurrency }}
      </a-descriptions-item>

      <a-descriptions-item label="月订单佣金金额(元)">
        {{ data.total_commission_fee | formatCurrency }}
      </a-descriptions-item>
    </a-descriptions>

    <a-table
      size="middle"
      :columns="tableColumns"
      :data-source="tableData"
      :pagination="false"
      row-key="index"
    />
  </div>
</template>

<script>
import { formatFloat } from '@/utils/filter'

export default {
  name: 'CommissionStatisticAgentMonthlyBillInfo',
  data() {
    return {
      tableData: []
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    },

    tableColumns() {
      var columnArr = [
        {
          title: '单元类型',
          dataIndex: 'unit_type'
        },
        {
          title: '订单个数',
          dataIndex: 'bill_count'
        },
        {
          title: '结算金额(元)',
          customRender: formatFloat,
          dataIndex: 'total_fee'
        },
        {
          title: '佣金金额(元)',
          customRender: formatFloat,
          dataIndex: 'total_commission_fee'
        }
      ]

      return columnArr
    },

    eventName() {
      return `${this.data.month}月结算订单`
    }
  },
  created() {
    this.assembleTableData()
  },
  methods: {
    assembleTableData() {
      this.tableData = [
        { index: 0, unit_type: '激活订单', bill_count: this.data.actived_count, total_fee: this.data.actived_fee, total_commission_fee: this.data.actived_commission_fee },
        { index: 1, unit_type: '续期订单', bill_count: this.data.renewed_count, total_fee: this.data.renewed_fee, total_commission_fee: this.data.renewed_commission_fee },
        { index: 2, unit_type: '订购订单', bill_count: this.data.subscribed_count, total_fee: this.data.subscribed_fee, total_commission_fee: this.data.subscribed_commission_fee }
      ]
    }
  }
}
</script>

